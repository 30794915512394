import React from 'react'
import { navigate } from 'gatsby'

const Index = () => {
  if (typeof window !== 'undefined') {
    navigate('/home');
  }
  return (
    <div />
  )
}

export default Index
